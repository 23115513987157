<template>
  <div
    :class="{ 'has-error': hasError }"
    class="control text date-control">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholderCalc"
        @input="onInput($event)"
        @change="onChange($event)"
        @focus="onFocus($event)"
        @keypress="onKeyPress($event)"
        @paste.prevent
        ref="control" />
  </div>
</template>

<script>
export default {
  name: 'date-control',
  props: {
    value: {
      type: Date
    },
    min: {
      type: Date
    },
    max: {
      type: Date
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date: null,
      inputValue: ''
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid()
    },
    placeholderCalc () {
      return this.placeholder || this.format(this.min || new Date())
    }
  },
  methods: {
    valid () {
      return fn.isEmpty(this.inputValue) || fn.isDate(this.unformat(this.inputValue), this.min, this.max)
    },
    format (date) {
      return fn.dateToString(date, true) || ''
    },
    unformat (input) {
      return fn.stringToDate(input, true)
    },
    typecheck (val) {
      var Reg = new RegExp(fn.user.date.chars)
      return Reg.test(val)
    },
    onFocus (Event) {
      this.$refs.control.select()
    },
    onInput (Event) {
      this.date = this.unformat(this.inputValue)
      this.$emit('input', this.date)
    },
    onChange (Event) {
      if (this.valid()) {
        this.inputValue = this.format(this.date)
      }
    },
    onKeyPress (Event) {
      if (!this.typecheck(Event.key)) {
        event.preventDefault()
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(date) {
        if (date !== this.date) {
          this.date = date
          this.inputValue = this.format(date)
        }
      }
    }
  }
}
</script>

<style lang="sass">
</style>