<template>
  <div class="page request-group-page typo">

    <!-- Meldungen -->
     <div
      v-if="$store.state.requestGroup.result > 0"
      class="row">
        <div
          :class="{
            'success': $store.state.requestGroup.result === 1,
            'error': $store.state.requestGroup.result >= 10
          }"
          class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text msg">
            <p v-if="$store.state.requestGroup.result === 1">
              Vielen Dank für Ihre Buchungsanfrage. Wir werden uns so schnell wie möglich mit Ihnen 
              in Verbindung setzen und Ihnen ein Angebot schicken.
            </p>
            <p v-if="$store.state.requestGroup.result === 10">
              Es tut uns leid, ein unbekannter Fehler ist aufgetreten. Ihre Anfrage wurde nicht 
              übermittelt. Bitte versuchen Sie es erneut oder setzen sich direkt mit uns in Verbindung.
            </p>
            <p v-if="$store.state.requestGroup.result === 11">
              Ihre Angaben sind unvollständig. Bitte prüfen Sie die markierten Felder und schicken 
              das Formular erneut ab. Vielen Dank.
            </p>
        </div>
    </div>

    <sections-elem :content="$store.state.page.content.sections"></sections-elem>

    <!-- Persönliche Angaben -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Persönliche Angaben</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.salutation.required">
            Anrede
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <select-control
          v-model="$store.state.requestGroup.data.salutation.value"
          :options="$store.state.requestGroup.data.salutation.options"
          :error="$store.state.requestGroup.data.salutation.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.prename.required">
            Vorname
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.prename.value"
          :error="$store.state.requestGroup.data.prename.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.surname.required">
            Name
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.surname.value"
          :error="$store.state.requestGroup.data.surname.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.street.required">
            Straße
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.street.value"
          :error="$store.state.requestGroup.data.street.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.zip.required">
            PLZ
        </label-control>
        <label-control
          :required="$store.state.requestGroup.data.town.required"
          class="is-hidden-sm">
            Ort
        </label-control>
      </div>
      <div class="col is-1 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.zip.value"
          :error="$store.state.requestGroup.data.zip.error">
        </text-control>
      </div>
      <div class="col is-4 is-hidden-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.town.value"
          :error="$store.state.requestGroup.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.town.required">
            Ort
        </label-control>
      </div>
      <div class="col is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.town.value"
          :error="$store.state.requestGroup.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.phone.required">
            Telefon
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.phone.value"
          :error="$store.state.requestGroup.data.phone.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.email.required">
            E-Mail
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestGroup.data.email.value"
          :isEmail="true"
          :error="$store.state.requestGroup.data.email.error">
        </text-control>
      </div>
    </div>

    <!-- Buchungsperiode -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Buchungszeitraum</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.arrivalDate.required">
            Anreise Datum
        </label-control>
        <label-control
          :required="$store.state.requestGroup.data.arrivalTime.required"
          class="is-hidden-sm">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <date-control
          v-model="$store.state.requestGroup.data.arrivalDate.value"
          :min="periodStart"
          :error="$store.state.requestGroup.data.arrivalDate.error">
        </date-control>
      </div>
      <div class="col is-2 is-hidden-sm control">
        <select-control
          v-model="$store.state.requestGroup.data.arrivalTime.value"
          :options="$store.state.requestGroup.data.arrivalTime.options"
          :error="$store.state.requestGroup.data.arrivalTime.error">
        </select-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.arrivalTime.required">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-8-sm control">
        <select-control
          v-model="$store.state.requestGroup.data.arrivalTime.value"
          :options="$store.state.requestGroup.data.arrivalTime.options"
          :error="$store.state.requestGroup.data.arrivalTime.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.departureDate.required">
            Abreise Datum
        </label-control>
        <label-control
          :required="$store.state.requestGroup.data.departureTime.required"
          class="is-hidden-sm">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <date-control
          v-model="$store.state.requestGroup.data.departureDate.value"
          :min="periodEnd"
          :error="$store.state.requestGroup.data.departureDate.error">
        </date-control>
      </div>
      <div class="col is-2 is-hidden-sm control">
        <select-control
          v-model="$store.state.requestGroup.data.departureTime.value"
          :options="$store.state.requestGroup.data.departureTime.options"
          :error="$store.state.requestGroup.data.departureTime.error">
        </select-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-4 label">
        <label-control
          :required="$store.state.requestGroup.data.departureTime.required">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-8 control">
        <select-control
          v-model="$store.state.requestGroup.data.departureTime.value"
          :options="$store.state.requestGroup.data.departureTime.options"
          :error="$store.state.requestGroup.data.departureTime.error">
        </select-control>
      </div>
    </div>

    <!-- Übernachtungen -->
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs info">
        <p v-if="periodDaysError === 2" class="has-error">
          Frühstes Anreisedatum ist der {{ periodStartString }}.
        </p>
        <p v-if="periodDaysError === 3" class="has-error">
          Das Abreisedatum muss nach dem Anreisedatum liegen.
        </p>
        <p v-if="periodDaysError === 4" class="has-error">
          Maximal {{ $store.state.page.calc.periodmax.value }} Übernachtungen sind möglich. Bitte korrigieren Sie An- oder Abreisedatum.
        </p>
        <p v-if="!periodDaysError">
          <strong>{{ $tc('nights', periodDays) }}</strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs info">
        Abweichende An- und Abreisezeiten nach Vereinbarung.
      </div>
    </div>

    <!-- Anzahl Personen -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Anzahl Personen</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.adultsCount.required">
            Ab 15 Jahre
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestGroup.data.adultsCount.value"
          :min="$store.state.requestGroup.data.adultsCount.min"
          :max="$store.state.page.calc.personsmax.value"
          :error="$store.state.requestGroup.data.adultsCount.error">
        </number-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.teensCount.required">
            5 bis 14 Jahre
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestGroup.data.teensCount.value"
          :min="$store.state.requestGroup.data.teensCount.min"
          :max="$store.state.page.calc.personsmax.value"
          :error="$store.state.requestGroup.data.teensCount.error">
        </number-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.childsCount.required">
            Kleinkinder bis 4 Jahre<span class="is-hidden-lg-only is-hidden-md-only is-hidden-sm-only">, im Beistellbett</span>
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestGroup.data.childsCount.value"
          :min="$store.state.requestGroup.data.childsCount.min"
          :max="$store.state.page.calc.childsmax.value"
          :error="$store.state.requestGroup.data.childsCount.error">
        </number-control>
      </div>
      <div class="col is-5 is-hidden-xs control">
        im Beistellbett
      </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm info">
        <p v-if="personsCountError === 1" class="has-error">
          Es können maximal {{ $store.state.page.calc.personsmax.value }} Personen übernachten. Bitte korrigieren Sie die Werte.
        </p>
        <p v-if="personsCountError === 2" class="has-error">
          Es können maximal {{ $store.state.page.calc.childsmax.value }} Kinder im Beistellbett übernachten. Bitte korrigieren Sie den Wert.
        </p>
        <p v-if="!personsCountError && personsCount === 0">
          Insgesamt können max. {{ $store.state.page.calc.personsmax.value }} Personen (ab 5 Jahre) übernachten. Zusätzlich können {{ $store.state.page.calc.childsmax.value }} Kleinkinder im Beistellbett untergebracht werden.
        </p>
        <p v-if="!personsCountError && personsCount > 0">
          <strong>
            {{ $tc('persons', personsCount) }}
            <span v-if="$store.state.requestGroup.data.childsCount.value > 0">, {{ $tc('children', childsCount ) }} im Beistellbett</span>
          </strong>
        </p>
      </div>
    </div>

    <!-- Verpflegung -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Vegetarische Verpflegung</h3>
      </div>
    </div>
    <div
      v-if="hasCateringText"
      class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
          <text-elem :text="$store.state.page.content.cateringtext"></text-elem>
        </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs control">
        <radio-control
          v-model="$store.state.requestGroup.data.catering.value"
          :options="cateringOptions"
          :error="$store.state.requestGroup.data.catering.error">
        </radio-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.veganCount.required">
            Anzahl Personen<span class="is-hidden-lg-only is-hidden-md-only is-hidden-sm-only">&nbsp;vegan</span>
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestGroup.data.veganCount.value"
          :error="$store.state.requestGroup.data.veganCount.error">
        </number-control>
      </div>
      <div class="col is-6 is-hidden-xs control">
        vegan
      </div>
    </div>

    <!-- Preisberechnung -->
    <div v-if="validCalculation">
      <div class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
          <hr />
          <h3 class="legend">Preisberechnung</h3>
        </div>
      </div>
      <div class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Grundpreis Haus
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          inkl. {{ calculation.base.including }} Personen
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.base.total | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.additionalAdults" class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          weitere ab 15 Jahre
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.additionalAdults.count }} x {{ calculation.additionalAdults.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.additionalAdults.total | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.additionalTeens" class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          weitere 5 bis 14 Jahre
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.additionalTeens.count }} x {{ calculation.additionalTeens.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.additionalTeens.total | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.cateringFull" class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          <span v-if="calculation.cateringFull.catering === 'breakfast'">Frühstück</span>
          <span v-if="calculation.cateringFull.catering === 'half'">Halbpension</span>
          <span v-if="calculation.cateringFull.catering === 'full'">Vollpension</span>
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.cateringFull.count }} x {{ calculation.cateringFull.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.cateringFull.total | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.cateringRed" class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          <span v-if="calculation.cateringRed.catering === 'breakfast'">Frühstück reduziert</span>
          <span v-if="calculation.cateringRed.catering === 'half'">Halbpension reduziert</span>
          <span v-if="calculation.cateringRed.catering === 'full'">Vollpension reduziert</span>
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.cateringRed.count }} x {{ calculation.cateringRed.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.cateringRed.total | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.sum.count > 1" class="row is-xs has-border calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Preis pro Übernachtung
        </div>
        <div class="col is-5 is-5-sm is-hidden-xs"></div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.sum.single | currency }}</strong>
        </div>
      </div>
      <div v-if="calculation.cateringDiscount" class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Vollpension
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          kein Mittagessen am Anreisetag
        </div>
        <div class="col is-2 is-4-sm is-5-xs total discount">
          <strong>{{ calculation.cateringDiscount.total | currency }}</strong>
        </div>
      </div>
      <div :class="{ 'has-border': calculation.sum.count === 1 }" class="row is-xs calculation sum">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Gesamtpreis
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          <strong>{{ $tc('days', calculation.sum.count) }}</strong>
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.sum.total | currency }}</strong>
        </div>
      </div>
      <div class="row calculation">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm info">
          Preis inkl. MWSt. und Kurtaxe | Preis pro Person und Tag: ca. {{ calculation.sum.average | currency }}
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
          <hr />
          <h3 class="legend">Preise</h3>
        </div>
      </div>
      <div class="row">
          <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
            <p>Bitte geben Sie zunächst den <strong>Buchungszeitraum</strong> und die <strong>Anzahl Personen</strong> ein.</p>
          </div>
      </div>
    </div>

    <!-- Unterbringung -->
    <div
      v-if="hasRoomText"
      class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
          <hr />
          <h3 class="legend">Unterbringung</h3>
        </div>
    </div>
    <div
      v-if="hasRoomText"
      class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
          <text-elem :text="$store.state.page.content.roomstext"></text-elem>
        </div>
    </div>

    <!-- Abschlusstext -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestGroup.data.message.required">
            Ihre Nachricht
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <textarea-control
          v-model="$store.state.requestGroup.data.message.value"
          :error="$store.state.requestGroup.data.message.error">
        </textarea-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
        <text-elem :text="$store.state.page.content.submittext"></text-elem>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm control">
        <checkbox-control
          v-model="$store.state.requestGroup.data.conditions.value"
          :error="$store.state.requestGroup.data.conditions.error">
            <text-elem :text="$store.state.page.content.conditionstext"></text-elem>
        </checkbox-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm action">
        <button-elem
          @click="onSubmit($event)"
          :spinner="submitProgress"
          class="is-medium is-outline">
            {{ $store.state.page.content.button.value }}
        </button-elem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '@/services/Scroll'
import TextControl from '@/components/controls/Text'
import DateControl from '@/components/controls/Date'
import NumberControl from '@/components/controls/Number'
import RadioControl from '@/components/controls/Radio'
import TextareaControl from '@/components/controls/Textarea'
import SelectControl from '@/components/controls/Select'
import CheckboxControl from '@/components/controls/Checkbox'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'request-group-page',
  components: {
    TextControl,
    DateControl,
    NumberControl,
    RadioControl,
    TextareaControl,
    SelectControl,
    CheckboxControl,
    LabelControl
  },
  data () {
    return {
      submitProgress: false
    }
  },
  created () {
    this.$store.commit('requestGroup/initForm')
  },
  computed: {
    ...mapGetters({
      periodStart: 'requestGroup/periodStart',
      periodEnd: 'requestGroup/periodEnd',
      cateringOptions: 'requestGroup/cateringOptions',
      periodDays: 'requestGroup/periodDays',
      periodDaysError: 'requestGroup/periodDaysError',
      personsCount: 'requestGroup/personsCount',
      childsCount: 'requestGroup/childsCount',
      personsCountError: 'requestGroup/personsCountError',
      calculation: 'requestGroup/calculation'
    }),
    periodStartString () {
      return fn.dateToString(this.periodStart, true)
    },
    validCalculation () {
      return this.calculation !== false
    },
    hasRoomText () {
      return this.$store.state.page.content.roomstext.value.length > 0
    },
    hasCateringText () {
      return this.$store.state.page.content.cateringtext.value.length > 0
    }
  },
  methods: {
    onSubmit (Event) {
      this.submitProgress = true
      this.$store.dispatch('requestGroup/submit')
      .then(() => {
        this.$nextTick(() => {
          Scroll.smooth(0)
          this.submitProgress = false
        })
      })
    }
  }
}
</script>

<style lang="sass">
.request-group-page
  .msg
    margin-bottom: m(2)
    padding: m(2) m(3)
    p
      color: $white
    &.error
      background-color: $warning-color
    &.success
      background-color: $success-color
  .label-control
    &+.label-control
      &:before
        margin-left: m(1)
        margin-right: m(1)
        content: "/"
  .action
    padding-top: m(4)
    text-align: center
  p
    &.has-error
      color: $red
  .calculation
    .total,
    .info
      text-align: right
    .discount
      color: $warning-color
    &.has-border
      margin-top: m(1)
      .col
        padding-top: m(2)
        border-top: 1px solid $border-color

+xs
  .request-group-page
    .col
      &.label
        padding-bottom: 0
      &.control
        padding-top: 0
    .calculation
      .info
        text-align: left
</style>